<template>
  <div class="client-configuration-table-container">
    <cp-table
      ref="cpTable"
      default-sort-by="createdAt"
      default-sort-direction="desc"
      :filters="filters"
      get-data-action="clientConfigurations/getAllClientConfigs"
      :fields="tableFields"
      :search-value="searchQueryParam"
      table-title="client configuration"
      @tableDataUpdated="onTableDataUpdated"
    >
      <template
        slot="createdAt"
        slot-scope="{ rowData: {item} }"
      >
        <span>
          {{ item.createdAt | standardDateFilter }}
        </span>
      </template>
      <template
        slot="updatedAt"
        slot-scope="{ rowData: {item} }"
      >
        <span>
          {{ item.updatedAt | standardDateFilter }}
        </span>
      </template>
      <template
        slot="actions"
        slot-scope="{ rowData: {item} }"
      >
        <div class="d-flex justify-content-start">
          <b-btn
            class="mr-3"
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.edit')"
            @click="editClientConfigs(item)"
          >
            <i
              class="ion ion-md-create blue-icon"
            />
            {{ $t('common.button.edit') }}
          </b-btn>
          <b-btn
            variant="default edit-operator-btn btn-xs md-btn-flat"
            :title="$t('common.button.delete')"
            @click="deleteClientConfigs(item)"
          >
            <i
              class="ion ion-ios-trash blue-icon"
            />
            {{ $t('common.button.delete') }}
          </b-btn>
        </div>
      </template>
    </cp-table>
  </div>
</template>

<script>
import CpTable from '~/components/shared/cp-table';
import tableFields from './options';

export default {
  name: 'CpClientConfigurationTable',
  components: {
    CpTable,
  },
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tableFields,
      totalTableData: [],
      searchQueryParam: this.$route.query.search || undefined,
      isEmptyList: false,
    };
  },
  watch: {
    $route(to) {
      this.searchQueryParam = to.query.search;
    },
  },
  methods: {
    onTableDataUpdated({ items, totalItems }) {
      this.totalTableData = items;
      this.isEmptyList = totalItems === 0;
    },
    editClientConfigs(item) {
      this.$emit('onEditConfigs', item);
    },
    deleteClientConfigs(item) {
      this.$emit('onDeleteConfigs', item);
    },
  },
};
</script>
<style lang="scss">
  .client-configuration-table-container {
    .blue-icon {
      color: rgb(4, 190, 196);
    }
  }
  .restrict-table-column {
    max-width: 350px;
  }
</style>
