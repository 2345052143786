<template>
  <cp-general-modal
    id="create-issuer"
    ref="clientConfigurationModal"
    title-icon="ios-person-add"
    :title="title"
    :ok-text="$t('common.button.save')"
    :cancel-text="$t('common.button.close')"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model.trim="model.name"
      name="name"
      :label="$t('clientConfiguration.addEditModal.labels.name')"
      validate="required|clientConfigName"
    />
    <cp-input
      v-model.trim="model.value"
      name="value"
      :label="$t('clientConfiguration.addEditModal.labels.value')"
      validate="required"
    />
    <cp-select
      v-model="model.domain"
      :disabled="!isAddMode"
      :label="$t('clientConfiguration.addEditModal.labels.domain')"
      :options="domainOptions"
      name="domain"
    />
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';

export default {
  name: 'AddEditClientConfigurationModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    domainOptions: {
      type: Array,
      default: () => [],
    },
    selectedDomain: {
      type: String,
      default: null,
    },
    fields: {
      type: [Object, String],
      default: () => {},
    },
    modelViewType: {
      type: String,
      default: 'addConfig',
      validator: value => ['addConfig', 'editConfig'].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      newIssuer: {
        blockchain: 'ethereum',
      },
      model: {
        domain: '',
        name: '',
        value: '',
      },
      isAddMode: true,
    };
  },
  computed: {
    title() {
      return this.isAddMode ? this.$t('clientConfiguration.addEditModal.addTitle')
        : this.$t('clientConfiguration.addEditModal.editTitle');
    },
  },
  watch: {
    modelViewType(newVal, oldVal) {
      if (newVal !== oldVal) this.isAddMode = newVal === 'addConfig';
    },
    fields: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) this.model = newVal;
        this.model = { ...newVal };
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('onClientConfiguration', this.model, this.modelViewType);
      this.model = {
        domain: '',
        name: '',
        value: '',
      };
      this.$refs.clientConfigurationModal.hide();
    },
  },
};
</script>
