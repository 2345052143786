export default [
  {
    key: 'name', label: 'Name', sortable: false, tdClass: 'align-middle',
  },
  {
    key: 'domain', label: 'Domain', sortable: false, tdClass: 'align-middle',
  },
  {
    key: 'value', label: 'Value', sortable: false, tdClass: 'align-middle restrict-table-column',
  },
  {
    key: 'createdAt', label: 'Created', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'updatedAt', label: 'Modified', sortable: true, tdClass: 'align-middle',
  },
  {
    key: 'actions',
    label: '',
    sortable: false,
    tdClass: 'text-nowrap align-middle text-center',
  },
];
