<template>
  <cp-filter
    ref="cpFilter"
    name="clientConfiguration"
    @input="updateFilter"
  >
    <div class="form-row align-items-center">
      <div class="col-md mb-6 filterBlock">
        <cp-select
          v-model="selectedDomain"
          name="issuerId"
          :label="$t('clientConfiguration.label.filter.domain')"
          :options="filterDomainOptions"
          :value="'null'"
          @input="onDomainFilterChange"
        />
      </div>

      <div class="col-md mb-6 filterBlock">
        <cp-select
          v-model="selectedName"
          name="name"
          :label="$t('clientConfiguration.label.filter.name')"
          :options="filterConfigKeyOptions"
          :value="'null'"
          @input="onConfigKeyFilterChange"
        />
      </div>
    </div>
  </cp-filter>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import CpFilter from '~/components/common/cp-filter';

export default {
  name: 'ClientConfigurationFilter',
  components: {
    CpSelect,
    CpFilter,
  },
  data() {
    return {
      filterDomainOptions: [],
      filterConfigKeyOptions: [],
      selectedDomain: '',
      selectedName: '',
    };
  },
  computed: {
    ...mapState('clientConfigurations', ['configurations']),
    ...mapGetters('clientConfigurations', [
      'getAllConfigKeyOptions',
      'getAllDomainOptions',
      'getConfigKeyOptionsByDomain',
      'getDomainOptionsByConfigKey',
    ]),
    configKeyOptions() {
      return [{ value: '', text: 'All' }].concat(this.getAllConfigKeyOptions);
    },
    domainOptions() {
      return [{ value: '', text: 'All' }].concat(this.getAllDomainOptions);
    },
  },
  watch: {
    filters: {
      handler(newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.selectedDomain = '';
          this.selectedName = '';
        }
      },
    },
  },
  async created() {
    await this.getAllClientConfigFilters();
    this.filterDomainOptions = this.domainOptions;
    this.filterConfigKeyOptions = this.configKeyOptions;
  },
  methods: {
    ...mapActions('clientConfigurations', ['getAllClientConfigFilters']),
    onDomainFilterChange(value) {
      if (!value) {
        this.filterConfigKeyOptions = this.configKeyOptions;
      } else {
        this.filterConfigKeyOptions = [{ value: '', text: 'All' }].concat(this.getConfigKeyOptionsByDomain(value));
      }
    },
    onConfigKeyFilterChange(value) {
      if (!value) {
        this.filterDomainOptions = this.domainOptions;
      } else {
        this.filterDomainOptions = [{ value: '', text: 'All' }].concat(this.getDomainOptionsByConfigKey(value));
      }
    },
    updateFilter(data) {
      if (data && data.issuerId === undefined && data.name === undefined) {
        this.selectedDomain = '';
        this.selectedName = '';
      }
      this.$emit('updateFilter', this.$refs.cpFilter.getDefaultValue);
    },
  },
};
</script>

<style>
  .filterBlock {
    margin: 0 2%;
    min-width: 25%;
  }
</style>
